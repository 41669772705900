import { FC } from 'react'
import { Items } from './Items'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../store/navigation/hooks'
import { toggleOverlay } from '../../store/navigation/navigationSlice'

import menu from '../../assets/icons/menu.svg'

import './mobile.scss'

export const Mobile: FC = () => {
  const dispatch = useDispatch()
  const showOverlay = useAppSelector((state) => state.navigation.showOverlay)

  return (
    <>
      <button
        className="button button--white mobile-menu-toggle"
        onClick={() => dispatch(toggleOverlay())}
      >
        <img src={menu} alt="menu" />
      </button>
      {showOverlay && (
        <div className="mobile-menu-overlay">
          <Items
            className="mobile-menu-items"
            onClick={() => dispatch(toggleOverlay())}
          />
        </div>
      )}
    </>
  )
}
