import React from 'react';
import { useTranslation } from 'react-i18next';

import { AvailabilityContent } from '../Availability/AvailabilityContent';

import './info.scss';

export const Info = () => {
  const { t } = useTranslation('availability');
  return (
    <div className="footer-info">
      <div className="footer-info-title footer-info-element">{t('title')}</div>

      <AvailabilityContent className="footer-info-element" />
    </div>
  );
};
