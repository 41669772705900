import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Frame } from '../Frame/Frame';
import { Hero } from '../Hero/Hero';

import pizzaJpg from '../../assets/images/pizza4.jpg';
import pizzaWebp from '../../assets/images/pizza4.webp';

import desertJpg from '../../assets/images/desert2.jpg';
import desertWebp from '../../assets/images/desert2.webp';

import ovenJpg from '../../assets/images/oven.jpg';
import ovenWebp from '../../assets/images/oven.webp';

import outdoorJpg from '../../assets/images/outdoor.jpg';
import outdoorPortraitJpg from '../../assets/images/outdoor-portrait.jpg';

import outdoorWebp from '../../assets/images/outdoor.webp';
import outdoorPortraitWebp from '../../assets/images/outdoor-portrait.jpg';

import './aboutUs.scss';
import { ImageCard } from '../ImageCard/ImageCard';

export const AboutUs: FC = () => {
  const { t } = useTranslation('aboutUs');
  return (
    <>
      <Hero
        title={t('title')}
        subtitle={t('subtitle')}
        image={{
          web: { webp: outdoorWebp, fallback: outdoorJpg },
          portrait: { webp: outdoorPortraitWebp, fallback: outdoorPortraitJpg },
          alt: '60-Secondi Garten',
        }}
      />
      <Frame id="aboutUs">
        <div className="about-us">
          <div className="about-us--header about-us-title">
            {t('paragraph-1-title')}
          </div>
          <ImageCard
            className="about-us-image1"
            image={{ webp: pizzaWebp, jpg: pizzaJpg, alt: 'pizza' }}
          />
          <ImageCard
            className="about-us-image2"
            image={{ webp: desertWebp, jpg: desertJpg, alt: 'desert' }}
          />
          <ImageCard
            className="about-us-image3"
            image={{ webp: ovenWebp, jpg: ovenJpg, alt: 'pizza-ofen' }}
          />
          <div className="about-us--content about-us-content1">
            <p>{t('paragraph-1-content')}</p>
            <div className="about-us--header">{t('paragraph-2-title')}</div>
            <p>{t('paragraph-2-content')}</p>
          </div>
          <div className="about-us--content about-us-content2">
            <div className="about-us--header">{t('paragraph-3-title')}</div>
            <p>{t('paragraph-3-content')}</p>
            <div className="about-us--header">{t('paragraph-4-title')}</div>
            <p>{t('paragraph-4-content')}</p>
          </div>
          <div className="about-us--content about-us-content3">
            <div className="about-us--header">{t('paragraph-5-title')}</div>
            <p>{t('paragraph-5-content')}</p>
            <div className="about-us--header">{t('paragraph-6-title')}</div>
            <p>{t('paragraph-6-content')}</p>
          </div>
        </div>
      </Frame>
    </>
  );
};
