import React from 'react';

import { ImageCard } from '../ImageCard/ImageCard';

import targaEurope2020Webp from '../../assets/thirdParty/targa_europe_2020.webp';
import targaEurope2020Jpg from '../../assets/thirdParty/targa_europe_2020.jpg';

import './targa2020.scss';

export const Targa2020 = () => {
  return (
    <ImageCard
      image={{
        webp: targaEurope2020Webp,
        jpg: targaEurope2020Jpg,
        alt: 'Top Europe 2020',
      }}
      className="targa-2020"
      limitedHeight={true}
    />
  );
};
