import React, { FC } from 'react';

import { Links } from './Links';
import { Contact } from './Contact';
import { Info } from './Info';
import { ExternalLinks } from './ExternalLinks';

import './footer.scss';

export const Footer: FC = () => {
  return (
    <div className="footer">
      <div className="footer--container">
        <Links />
        <Contact />
        <Info />
        <ExternalLinks />
        <div className="footer--copyright">&copy; 60-Secondi</div>
      </div>
    </div>
  );
};
