import React, { FC } from 'react';

import './googleMaps.scss';

export const GoogleMaps: FC = () => (
  <iframe
    title="60 Secondi Maps"
    className="google-maps"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2661.3027227499274!2d11.58726061595306!3d48.16224657922534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e75b3bc84697f%3A0x322a2fbfa315dc7b!2s60%20Secondi%20Pizza%20Napoletana!5e0!3m2!1sde!2sde!4v1571594368837!5m2!1sde!2sde"
    frameBorder="0"
  />
);
