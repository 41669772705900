import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Frame } from '../Frame/Frame'
import { Image } from '../Image/Image'

import page1Jpg from '../../assets/menu-v2/menu1.jpeg'
import page2Jpg from '../../assets/menu-v2/menu2.jpeg'
import page3Jpg from '../../assets/menu-v2/menu3.jpeg'
import page4Jpg from '../../assets/menu-v2/menu4.jpeg'
import page5Jpg from '../../assets/menu-v2/menu5.jpeg'
import page6Jpg from '../../assets/menu-v2/menu6.jpeg'
import page1Webp from '../../assets/menu-v2/menu1.webp'
import page2Webp from '../../assets/menu-v2/menu2.webp'
import page3Webp from '../../assets/menu-v2/menu3.webp'
import page4Webp from '../../assets/menu-v2/menu4.webp'
import page5Webp from '../../assets/menu-v2/menu5.webp'
import page6Webp from '../../assets/menu-v2/menu6.webp'

import './menu.scss'

export const Menu: FC = () => {
  const { t } = useTranslation('menu')
  const [selectedMenu, setSelectedMenu] = useState(1)

  const renderSelectedMenu = () => {
    switch (selectedMenu) {
      case 1:
        return (
          <Image
            defaultSource={[{ src: page1Webp, type: 'webp' }]}
            fallbackSource={[{ src: page1Jpg, type: 'jpg' }]}
            alt="Speisekarte 1"
          />
        )
      case 2:
        return (
          <Image
            defaultSource={[{ src: page2Webp, type: 'webp' }]}
            fallbackSource={[{ src: page2Jpg, type: 'jpg' }]}
            alt="Speisekarte 2"
          />
        )
      case 3:
        return (
          <Image
            defaultSource={[{ src: page3Webp, type: 'webp' }]}
            fallbackSource={[{ src: page3Jpg, type: 'jpg' }]}
            alt="Speisekarte 3"
          />
        )
      case 4:
        return (
          <Image
            defaultSource={[{ src: page4Webp, type: 'webp' }]}
            fallbackSource={[{ src: page4Jpg, type: 'jpg' }]}
            alt="Speisekarte 4"
          />
        )
      case 5:
        return (
          <Image
            defaultSource={[{ src: page5Webp, type: 'webp' }]}
            fallbackSource={[{ src: page5Jpg, type: 'jpg' }]}
            alt="Speisekarte 5"
          />
        )
      case 6:
        return (
          <Image
            defaultSource={[{ src: page6Webp, type: 'webp' }]}
            fallbackSource={[{ src: page6Jpg, type: 'jpg' }]}
            alt="Speisekarte 6"
          />
        )
    }
  }

  const renderSelectedMenuWeb = () => {
    switch (selectedMenu) {
      case 1:
        return (
          <>
            <Image
              defaultSource={[{ src: page1Webp, type: 'webp' }]}
              fallbackSource={[{ src: page1Jpg, type: 'jpg' }]}
              alt="Speisekarte 1"
            />
            <Image
              defaultSource={[{ src: page2Webp, type: 'webp' }]}
              fallbackSource={[{ src: page2Jpg, type: 'jpg' }]}
              alt="Speisekarte 2"
            />
          </>
        )
      case 2:
        return (
          <>
            <Image
              defaultSource={[{ src: page1Webp, type: 'webp' }]}
              fallbackSource={[{ src: page1Jpg, type: 'jpg' }]}
              alt="Speisekarte 1"
            />
            <Image
              defaultSource={[{ src: page2Webp, type: 'webp' }]}
              fallbackSource={[{ src: page2Jpg, type: 'jpg' }]}
              alt="Speisekarte 2"
            />
          </>
        )
      case 3:
        return (
          <>
            <Image
              defaultSource={[{ src: page3Webp, type: 'webp' }]}
              fallbackSource={[{ src: page3Jpg, type: 'jpg' }]}
              alt="Speisekarte 3"
            />
            <Image
              defaultSource={[{ src: page4Webp, type: 'webp' }]}
              fallbackSource={[{ src: page4Jpg, type: 'jpg' }]}
              alt="Speisekarte 4"
            />
          </>
        )
      case 4:
        return (
          <>
            <Image
              defaultSource={[{ src: page3Webp, type: 'webp' }]}
              fallbackSource={[{ src: page3Jpg, type: 'jpg' }]}
              alt="Speisekarte 3"
            />
            <Image
              defaultSource={[{ src: page4Webp, type: 'webp' }]}
              fallbackSource={[{ src: page4Jpg, type: 'jpg' }]}
              alt="Speisekarte 4"
            />
          </>
        )
      case 5:
        return (
          <>
            <Image
              defaultSource={[{ src: page5Webp, type: 'webp' }]}
              fallbackSource={[{ src: page5Jpg, type: 'jpg' }]}
              alt="Speisekarte 5"
            />
            <Image
              defaultSource={[{ src: page6Webp, type: 'webp' }]}
              fallbackSource={[{ src: page6Jpg, type: 'jpg' }]}
              alt="Speisekarte 6"
            />
          </>
        )
      case 6:
        return (
          <>
            <Image
              defaultSource={[{ src: page5Webp, type: 'webp' }]}
              fallbackSource={[{ src: page5Jpg, type: 'jpg' }]}
              alt="Speisekarte 5"
            />
            <Image
              defaultSource={[{ src: page6Webp, type: 'webp' }]}
              fallbackSource={[{ src: page6Jpg, type: 'jpg' }]}
              alt="Speisekarte 6"
            />
          </>
        )
    }
  }

  const getButtonColor = (item: number) =>
    selectedMenu === item ? 'button--black' : 'button--white'

  return (
    <Frame id="menu" className="menu">
      <div className="menu-title title">{t('title')}</div>
      <div className="menu-buttons">
        <div className="menu-buttons-title">{t('page')}</div>
        <div className="menu-buttons-item">
          <div
            className={`button menu-button ${getButtonColor(1)}`}
            onClick={() => {
              setSelectedMenu(1)
            }}
          >
            1
          </div>
          <div
            className={`button menu-button ${getButtonColor(2)}`}
            onClick={() => {
              setSelectedMenu(2)
            }}
          >
            2
          </div>
          <div
            className={`button menu-button ${getButtonColor(3)}`}
            onClick={() => {
              setSelectedMenu(3)
            }}
          >
            3
          </div>
          <div
            className={`button menu-button ${getButtonColor(4)}`}
            onClick={() => {
              setSelectedMenu(4)
            }}
          >
            4
          </div>
          <div
            className={`button menu-button ${getButtonColor(5)}`}
            onClick={() => {
              setSelectedMenu(5)
            }}
          >
            5
          </div>
          <div
            className={`button menu-button ${getButtonColor(6)}`}
            onClick={() => {
              setSelectedMenu(6)
            }}
          >
            6
          </div>
        </div>
        <div className="menu-buttons-item-web">
          <div
            className={`button menu-button ${getButtonColor(1)}`}
            onClick={() => {
              setSelectedMenu(1)
            }}
          >
            1-2
          </div>
          <div
            className={`button menu-button ${getButtonColor(3)}`}
            onClick={() => {
              setSelectedMenu(3)
            }}
          >
            3-4
          </div>
          <div
            className={`button menu-button ${getButtonColor(5)}`}
            onClick={() => {
              setSelectedMenu(5)
            }}
          >
            5-6
          </div>
        </div>
      </div>
      <div className="menu-card">{renderSelectedMenu()}</div>
      <div className="menu-card-web">{renderSelectedMenuWeb()}</div>
    </Frame>
  )
}
