import React, { FC } from 'react';

import './card.scss';

type CardProps = {
  id?: string;
  children: React.ReactNode;
  className: string;
};

export const Card: FC<CardProps> = ({ id, children, className }) => {
  return (
    <div className={`card ${className}`} id={id}>
      {children}
    </div>
  );
};
