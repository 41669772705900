import React from 'react';

import { ImageCard } from '../ImageCard/ImageCard';

import desertJpg from '../../assets/images/desert2.jpg';
import desertWebp from '../../assets/images/desert2.webp';

import './availabilityImage.scss';

export const AvailabilityImage = () => {
  return (
    <ImageCard
      image={{ webp: desertWebp, jpg: desertJpg, alt: 'desert' }}
      className="availability-image"
      limitedHeight={true}
    />
  );
};
