import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { SmoothLink } from '../SmoothLink/SmoothLink'
import { selectMenu } from '../../store/navigation/navigationSlice'

import './links.scss'

export const Links: FC = () => {
  const { t } = useTranslation('footer')
  const dispatch = useDispatch()
  return (
    <div className="footer-links">
      <SmoothLink
        pathname="/"
        hash="hero"
        className="link"
        onClick={() => dispatch(selectMenu('HOME'))}
      >
        {t('title')}
      </SmoothLink>
      <SmoothLink
        pathname="/aboutUs"
        hash="hero"
        className="link"
        onClick={() => dispatch(selectMenu('ABOUT_US'))}
      >
        {t('aboutUs')}
      </SmoothLink>
      <SmoothLink
        pathname="/"
        hash="menu"
        className="link"
        onClick={() => dispatch(selectMenu('MENU'))}
      >
        {t('menu')}
      </SmoothLink>
      <SmoothLink
        pathname="/"
        hash="availability"
        className="link"
        onClick={() => dispatch(selectMenu('AVAILABLITY'))}
      >
        {t('availability')}
      </SmoothLink>
      <SmoothLink
        pathname="/"
        hash="availability"
        className="link"
        onClick={() => dispatch(selectMenu('RESERVATIONS'))}
      >
        {t('reservation')}
      </SmoothLink>
      <SmoothLink
        pathname="/"
        hash="contact"
        className="link"
        onClick={() => dispatch(selectMenu('CONTACT'))}
      >
        {t('contact')}
      </SmoothLink>
      <SmoothLink
        pathname="/imprint"
        hash="imprint"
        className="link"
        onClick={() => dispatch(selectMenu('IMPRINT'))}
      >
        {t('imprint')}
      </SmoothLink>
    </div>
  )
}
