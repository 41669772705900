import React from 'react';

import { ImageCard } from '../ImageCard/ImageCard';

import targaEurope2019Webp from '../../assets/thirdParty/targa_europe_2019.webp';
import targaEurope2019Jpg from '../../assets/thirdParty/targa_europe_2019.jpg';

import './targa2019.scss';

export const Targa2019 = () => {
  return (
    <ImageCard
      image={{
        webp: targaEurope2019Webp,
        jpg: targaEurope2019Jpg,
        alt: 'Top Europe 2019',
      }}
      className="targa-2019"
      limitedHeight={true}
    />
  );
};
