import React, { FC, PropsWithChildren } from 'react'

import { Card } from '../Card/Card'

import './contentCard.scss'

type ContentCardProps = {
  id?: string
  className: string
}

export const ContentCard: FC<PropsWithChildren<ContentCardProps>> = ({
  id,
  className,
  children,
}) => {
  return (
    <Card className={`${className}`} id={id}>
      <div className="content-card-container">{children}</div>
    </Card>
  )
}
