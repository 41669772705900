import React from 'react';

import { ImageCard } from '../ImageCard/ImageCard';

import pizzaJpg from '../../assets/images/pizza4.jpg';
import pizzaWebp from '../../assets/images/pizza4.webp';

import './informationImage.scss';

export const InformationImage = () => {
  return (
    <ImageCard
      image={{ webp: pizzaWebp, jpg: pizzaJpg, alt: 'desert' }}
      className="information-image"
      limitedHeight={true}
    />
  );
};
