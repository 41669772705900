import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AboutUs } from '../AboutUs/AboutUs'
import { Footer } from '../Footer/Footer'

import { Header } from '../Header/Header'
import { Imprint } from '../Imprint/Imprint'
import { Main } from '../Main/Main'

import './app.scss'
import ScrollToAnchor from '../../utils/ScrollToAnchor'

export const App = () => {
  return (
    <div className="App">
      {/* <MemoryRouter>
        <> */}
      <BrowserRouter>
        <ScrollToAnchor />
        <Header />
        <Routes>
          <Route index element={<Main />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/imprint" element={<Imprint />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      {/* </>
      </MemoryRouter> */}
    </div>
  )
}
