import React, { FC } from 'react'

import { Image } from '../Image/Image'
import { LanguageSelector } from '../LanguageSelector/LanguageSelector'

import radioGongWebp from '../../assets/icons/radio_gong.webp'
import radioGongPng from '../../assets/icons/radio_gong.png'

import './hero.scss'

type HeroProps = {
  title: string
  subtitle: string
  image: {
    web: {
      webp: string
      fallback: string
    }
    portrait: {
      webp: string
      fallback: string
    }
    alt: string
  }
}

export const Hero: FC<HeroProps> = ({ title, subtitle, image }) => {
  return (
    <div className="hero" id="hero">
      <div className="hero-teaser--wrapper">
        <div className="hero-teaser hero-teaser--title">{title}</div>
        <div className="hero-teaser hero-teaser--subtitle">{subtitle}</div>
      </div>
      <Image
        defaultSource={[{ src: radioGongWebp, type: 'webp' }]}
        fallbackSource={[{ src: radioGongPng, type: 'png' }]}
        alt="Radio Gong 96.3"
        className="hero-radio-gong"
      />
      <Image
        defaultSource={[
          { src: image.web.webp, media: '(min-width: 800px)', type: 'webp' },
          {
            src: image.portrait.webp,
            media: '(max-width: 799px)',
            type: 'webp',
          },
        ]}
        fallbackSource={[
          { src: image.web.fallback, media: '(min-width: 800px)', type: 'png' },
          {
            src: image.portrait.fallback,
            media: '(max-width: 799px)',
            type: 'png',
          },
        ]}
        alt={image.alt}
        className="hero-background-img"
      />
      <div className="hero-language-switcher">
        <LanguageSelector />
      </div>
    </div>
  )
}
