import { useTranslation } from 'react-i18next'

import { Hero } from '../Hero/Hero'
import { Information } from '../Information/Information'
import { InformationImage } from '../Information/InformationImage'
import { Availability } from '../Availability/Availability'
import { AvailabilityImage } from '../Availability/AvailabilityImage'
import { Frame } from '../Frame/Frame'
import { Menu } from '../Menu/Menu'
import { Targa2019 } from '../Awards/Targa2019'
import { Targa2020 } from '../Awards/Targa2020'
import { Contact } from '../Contact/Contact'
// import { Announcement } from '../Announcement/Announcement'

import ovenPng from '../../assets/images/oven2.png'
import ovenPortraitPng from '../../assets/images/oven2-portrait.png'
import ovenWebp from '../../assets/images/oven2.webp'
import ovenPortraitWebp from '../../assets/images/oven2-portrait.webp'

import './main.scss'

export const Main = () => {
  const { t } = useTranslation('main')

  return (
    <>
      <Hero
        title={t('title')}
        subtitle={t('subtitle')}
        image={{
          web: { webp: ovenWebp, fallback: ovenPng },
          portrait: { webp: ovenPortraitWebp, fallback: ovenPortraitPng },
          alt: 'Pizzaofen',
        }}
      />
      <Frame>
        <div className="main__grid">
          {/* <Announcement /> */}
          <Information />
          <InformationImage />
          <Availability />
          <AvailabilityImage />
          <Menu />
          <Targa2019 />
          <Targa2020 />
          <Contact />
        </div>
      </Frame>
    </>
  )
}
