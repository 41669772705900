import React, { FC } from 'react';

import { Card } from '../Card/Card';
import { Image } from '../Image/Image';

import './imageCard.scss';

type ImageCardProps = {
  id?: string;
  children?: React.ReactNode;
  className: string;
  image: {
    webp: string;
    jpg: string;
    alt: string;
  };
  limitedHeight?: boolean;
};

export const ImageCard: FC<ImageCardProps> = ({
  id,
  children,
  className,
  image: { webp, jpg, alt },
  limitedHeight = false,
}) => {
  return (
    <Card
      className={`image-card ${
        limitedHeight ? 'image-card--max-height' : ''
      } ${className}`}
      id={id}
    >
      <Image
        defaultSource={[{ src: webp, type: 'webp' }]}
        fallbackSource={[{ src: jpg, type: 'jpg' }]}
        alt={alt}
        className='image-card__image'
      />
      {children && <div className='image-card__text-container'>{children}</div>}
    </Card>
  );
};
