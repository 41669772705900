import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './languageSelector.scss';

type Language = 'de' | 'en';

export const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('de');

  const changeLanguage = (lng: Language) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
  };

  const showLanguageSwitcher = () => {
    switch (language) {
      case 'de':
        return (
          <button
            className='button button--white language-selector-button'
            onClick={() => changeLanguage('en')}
          >
            switch to english
          </button>
        );
      case 'en':
        return (
          <button
            className='button button--white language-selector-button'
            onClick={() => changeLanguage('de')}
          >
            wechsel zu deutsch
          </button>
        );
      default:
        return (
          <button
            className='button button--white language-selector-button'
            onClick={() => changeLanguage('en')}
          >
            switch to english
          </button>
        );
    }
  };

  return showLanguageSwitcher();
};
