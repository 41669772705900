import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { availability } from "../../data/availability";

import "./availability.scss";

type AvailabilityContentProps = {
  className: string;
};

export const AvailabilityContent: FC<AvailabilityContentProps> = ({
  className,
}) => {
  const { t } = useTranslation("availability");
  return (
    <>
      <div className={className}>
        {t("monday")} - {t("thursday")}: {t("from")}{" "}
        {availability["monday"].from} {t("to")} {availability["monday"].to}{" "}
        {t("clock")}
      </div>
      <div className={className}>
        {t("friday")}: {t("from")} {availability["friday"].from} {t("to")}{" "}
        {availability["friday"].to} {t("clock")}
      </div>
      <div className={className}>
        {t("saturday")}: {t("from")} {availability["saturday"].from} {t("to")}{" "}
        {availability["saturday"].to} {t("clock")}
      </div>
      <div className={className}>
        {t("sunday")}: {t("from")} {availability["sunday"].from} {t("to")}{" "}
        {availability["sunday"].to} {t("clock")}
      </div>
      <div className={`${className} font-small`}>{t("kitchenInfo")}</div>
    </>
  );
};
