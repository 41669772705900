import React from 'react'
import { useTranslation } from 'react-i18next'

import { ContentCard } from '../ContentCard/ContentCard'

import phone from '../../assets/icons/phone.svg'

import './information.scss'

export const Information = () => {
  const { t } = useTranslation('information')

  return (
    <ContentCard id="reservations" className="information">
      <div className="information-title font-bigger">{t('title')}</div>
      <div className="information-content">
        <div>{t('content1')}</div>
        <div className="font-big">{t('content2')}</div>
        <div>{t('content3')}</div>
        <div className="information-reservation-buttons">
          <a
            className="button button--white contact-btn contact-phone-btn"
            href="tel:+4908938899177"
          >
            <img src={phone} alt="phone" />
            089 - 388 99 177
          </a>
        </div>
        <div>{t('content4')}</div>
      </div>
    </ContentCard>
  )
}
