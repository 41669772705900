import React from 'react';
import { useTranslation } from 'react-i18next';

import './contact.scss';

export const Contact = () => {
  const { t } = useTranslation('footer');
  return (
    <div className="footer-contact">
      <div className="footer-contact-title footer-contact-element">
        {t('contact')}
      </div>
      <div className="footer-contact-element">
        <div>Occamstraße 11</div>
        <div>80802 {t('cityName')}</div>
      </div>
      <a className="footer-contact-element" href="tel:+4908938899177">
        089 388 99 177
      </a>
      <a className="footer-contact-element" href="mailto:info@60-secondi.de">
        info@60-secondi.de
      </a>
    </div>
  );
};
