import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type MenuItem =
  | 'HOME'
  | 'ABOUT_US'
  | 'MENU'
  | 'AVAILABLITY'
  | 'CONTACT'
  | 'IMPRINT'
  | 'RESERVATIONS'

export interface NavigationState {
  selectedNavigationItem: MenuItem
  showOverlay: boolean
}

const initialState: NavigationState = {
  selectedNavigationItem: 'HOME',
  showOverlay: false,
}

export const navigationSlice = createSlice({
  name: 'navigator',
  initialState,
  reducers: {
    selectMenu: (state, action: PayloadAction<MenuItem>) => {
      state.selectedNavigationItem = action.payload
    },
    toggleOverlay: (state) => {
      state.showOverlay = !state.showOverlay
    },
    setOverlay: (state, action: PayloadAction<boolean>) => {
      state.showOverlay = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { selectMenu, toggleOverlay, setOverlay } = navigationSlice.actions

export default navigationSlice.reducer
