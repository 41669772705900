import { FC } from 'react';

import './frame.scss';

type FrameProps = {
  id?: string;
  children: React.ReactNode;
  className?: string;
};

export const Frame: FC<FrameProps> = ({ id, children, className }) => {
  return (
    <div className={`wrapper ${className}`} id={id}>
      <div className="wrapper--inner">{children}</div>
    </div>
  );
};
