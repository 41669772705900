import React from "react";
import { useTranslation } from "react-i18next";

import { TextCard } from "../TextCard/TextCard";
import { availability } from "../../data/availability";

import "./availability.scss";

type DayRange = {
  startDate: string;
  endDate: string;
};

type TimeRange = {
  startTime: string;
  endTime: string;
};

export const Availability = () => {
  const { t } = useTranslation("availability");

  const availibilityText = (dayRange: DayRange, timeRange: TimeRange) => {
    const stringifiedDayRange =
      `${dayRange.startDate} ` +
      (dayRange.endDate === dayRange.startDate ? "" : `- ${dayRange.endDate} `);
    const stringifiedTimeRange = `${t("from")} ${timeRange.startTime} ${t(
      "to"
    )} ${timeRange.endTime} ${t("clock")}`;
    return stringifiedDayRange + stringifiedTimeRange;
  };

  const paragraphs = [
    availibilityText(
      { startDate: t("monday"), endDate: t("thursday") },
      {
        startTime: availability["monday"].from,
        endTime: availability["monday"].to,
      }
    ),
    availibilityText(
      { startDate: t("friday"), endDate: t("friday") },
      {
        startTime: availability["friday"].from,
        endTime: availability["friday"].to,
      }
    ),
    availibilityText(
      { startDate: t("saturday"), endDate: t("saturday") },
      {
        startTime: availability["saturday"].from,
        endTime: availability["saturday"].to,
      }
    ),
    availibilityText(
      { startDate: t("sunday"), endDate: t("sunday") },
      {
        startTime: availability["sunday"].from,
        endTime: availability["sunday"].to,
      }
    ),
    t("kitchenInfo"),
  ];
  return (
    <TextCard
      id="availability"
      className="availability"
      title={t("title")}
      paragraphs={paragraphs}
    />
  );
};
