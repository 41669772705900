import React, { FC } from 'react';

type Webp = {
  type: 'webp';
};

type Jpg = {
  type: 'jpg';
};

type Png = {
  type: 'png';
};

type Img = {
  src: string;
  media?: string;
};

type ImageProps = {
  defaultSource: (Webp & Img)[];
  fallbackSource: ((Png | Jpg) & Img)[];
  alt: string;
  className?: string;
};

export const Image: FC<ImageProps> = ({
  defaultSource,
  fallbackSource,
  alt,
  className,
}) => (
  <picture>
    {defaultSource.map((source) => {
      return source.media ? (
        <source
          key={source.src}
          media={source.media}
          srcSet={source.src}
          type={`image/${source.type}`}
        />
      ) : (
        <source
          key={source.src}
          srcSet={source.src}
          type={`image/${source.type}`}
        />
      );
    })}
    {fallbackSource.map((source) => {
      return source.media ? (
        <source
          key={source.src}
          media={source.media}
          srcSet={source.src}
          type={`image/${source.type}`}
        />
      ) : (
        <source
          key={source.src}
          srcSet={source.src}
          type={`image/${source.type}`}
        />
      );
    })}
    <img
      loading="lazy"
      src={fallbackSource[0].src}
      alt={alt}
      title={alt}
      className={className}
    />
  </picture>
);
