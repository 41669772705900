type Availability = {
  from: string
  to: string
}

type WeekdayAvailability = {
  [day: string]: Availability
}

export const availability: WeekdayAvailability = {
  monday: {
    from: '17',
    to: '22:30',
  },
  tuesday: {
    from: '17',
    to: '22:30',
  },
  wednesday: {
    from: '17',
    to: '22:30',
  },
  thursday: {
    from: '17',
    to: '22:30',
  },
  friday: {
    from: '17',
    to: '23',
  },
  saturday: {
    from: '12',
    to: '23',
  },
  sunday: {
    from: '12',
    to: '22:30',
  },
}
