import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Image } from '../Image/Image'
import { Mobile } from '../Navigation/Mobile'
import { Web } from '../Navigation/Web'
import { SmoothLink } from '../SmoothLink/SmoothLink'
import { selectMenu, setOverlay } from '../../store/navigation/navigationSlice'

import logoPng from '../../assets/icons/logo64.png'
import logoWebp from '../../assets/icons/logo64.webp'

import './header.scss'

export const Header: FC = () => {
  const { t } = useTranslation('header')
  const dispatch = useDispatch()

  return (
    <div className="header">
      <div className="header-wrapper">
        <div className="header-wrapper--left">
          <SmoothLink
            pathname="/"
            hash="hero"
            onClick={() => {
              dispatch(selectMenu('HOME'))
              dispatch(setOverlay(false))
            }}
          >
            <div className="header-logo">
              <Image
                defaultSource={[{ src: logoWebp, type: 'webp' }]}
                fallbackSource={[{ src: logoPng, type: 'png' }]}
                alt="60-Secondi-logo"
              />
              <div className={`button button--black`}>{t('title')}</div>
            </div>
          </SmoothLink>
        </div>
        <div className="header-wrapper--right">
          <div className="header-menu--web">
            <Web />
          </div>
          <div className="header-menu--mobile">
            <Mobile />
          </div>
        </div>
      </div>
    </div>
  )
}
