import React from 'react';

import { Image } from '../Image/Image';

import facebookPng from '../../assets/icons/facebook_b.png';
import facebookWebp from '../../assets/icons/facebook_b.webp';
import lieferandoPng from '../../assets/icons/lieferando_b.png';
import lieferandoWebp from '../../assets/icons/lieferando_b.webp';
import instagramPng from '../../assets/icons/instagram_b.png';
import instagramWebp from '../../assets/icons/instagram_b.webp';

import './externalLinks.scss';

export const ExternalLinks = () => {
  return (
    <div className="footer-links-external">
      <a
        className="link"
        href="https://www.facebook.com/60secondipizza/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          defaultSource={[{ src: facebookWebp, type: 'webp' }]}
          fallbackSource={[{ src: facebookPng, type: 'png' }]}
          alt="60-Secondi facebook"
        />
      </a>
      <a
        className="link"
        href="https://www.instagram.com/60secondi_pizza/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          defaultSource={[{ src: instagramWebp, type: 'webp' }]}
          fallbackSource={[{ src: instagramPng, type: 'png' }]}
          alt="60-Secondi instagram"
        />
      </a>
      <a
        className="link"
        href="https://www.lieferando.de/60-secondi-pizza-napoletana"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          defaultSource={[{ src: lieferandoWebp, type: 'webp' }]}
          fallbackSource={[{ src: lieferandoPng, type: 'png' }]}
          alt="60-Secondi lieferando"
        />
      </a>
    </div>
  );
};
