import './smoothLink.scss'
import { NavLink } from 'react-router-dom'

type Props = {
  pathname: string
  children: React.ReactNode
  hash?: string
  onClick?(): void
  className?: string
}

export const SmoothLink: React.FC<Props> = ({
  children,
  onClick,
  pathname,
  hash,
  className,
}) => {
  return (
    <NavLink
      to={`${pathname}${hash ? `#${hash}` : ''}`}
      className={className}
      onClick={onClick}
      end
    >
      {children}
    </NavLink>
  )
}
