import React from 'react'
import './translations/i18n'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'

import { App } from './components/App/App'
import { store } from './store'

import './index.scss'

const domNode = document.getElementById('root')

if (!domNode) {
  throw new Error('Root element not found')
}

const root = createRoot(domNode)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
