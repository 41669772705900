import React, { FC } from 'react';

import { Card } from '../Card/Card';

import './textCard.scss';

type TextCardProps = {
  id?: string;
  title: string;
  paragraphs: string[];
  className: string;
};

export const TextCard: FC<TextCardProps> = ({
  id,
  title,
  paragraphs,
  className,
}) => {
  return (
    <Card className={`${className}`} id={id}>
      <div className='text-card-container'>
        <div className='title'>{title}</div>
        <div className='text-card-content'>
          {paragraphs.map((paragraph, index) => (
            <div key={index} className='text-card-content--item'>
              {paragraph}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};
